<template>
  <div class="section">
    <b-navbar>
      <!-- search bar -->
      <template #brand>
        <div class="field has-addons search-bar">
          <div class="control has-icons-left">
            <input class="input is-small" type="text" placeholder="输入关键字" v-model="search_keyword">
            <span class="icon is-left is-small">
              <i class="fa fa-search"></i>
            </span>
          </div>
          <div class="control">
            <a class="button is-success is-small" @click="search_action()">搜索</a>
          </div>
        </div>
      </template>

      <template #start>
          <b-navbar-item href="#/">
            本月库存
          </b-navbar-item>
          <b-navbar-item href="#/detail">
            详细信息
          </b-navbar-item>
          <b-navbar-item href="#/graph">
            月度总结
          </b-navbar-item>
      </template>
    </b-navbar>

    <h1>{{ today }} - 本月库存</h1>
    <b-collapse
        aria-id="contentIdForA11y2"
        class="panel"
        animation="slide"
        
        v-for="cate in sorted_categories" :key="cate">
        <template #trigger>
            <div
                class="panel-heading"
                role="button"
                aria-controls="contentIdForA11y2">
                <strong>{{ cate }}</strong>
            </div>
        </template>
        <div class="panel-block">
          <div class="media-content">
            <table class="table is-fullwidth is-hoverable">
              <thead>
                <tr>
                  <th>颜色</th>
                  <th>库存</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in records[cate]" :key="item[0]">
                  <td><span class="tag" v-bind:class="item">{{item[0]}}</span></td>
                  <td>{{item[1]}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </b-collapse>
    <a class="button is-fullwidth hidden-button" href="#/etf">ETF隐藏</a>
    <b-loading is-full-page v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import moment from "moment"
import utils from "../utils"

export default {
  name: 'Total',
  data() {
    return {
      sorted_categories: [],
      records: [],
      today: "",
      raw_data: [],
      search_keyword: "",
      isLoading: false
    }
  },
  created() {
    this.isLoading = true;
    // Load data
    var this_month = utils.get_this_month()
    var message = localStorage.getItem("sessionId") + this_month
    var payload = {
      month: btoa(message)
    }
    this.axios.post("https://0wm5vbpwfa.execute-api.ap-southeast-2.amazonaws.com/fetch", payload)
      .then(response => {
        if (response.data.length > 0) {
          this.raw_data = response.data
          this.filter_results(this.raw_data)
          this.isLoading = false
          this.today = this.get_lastest_date(this.raw_data)
        }
        else {
          this.load_last_month_data()
        }
      })
  },
  mounted() {
    if (!localStorage.getItem("sessionId")) {
      this.$router.push('/login')
    }
    else if (localStorage.getItem("sessionId") != btoa("6871643")) {
      this.$router.push('/login')
    }
  },
  methods: {
    group_by_col(raw_data, col_name) {
      var groups = {}
      for (var item of raw_data) {
        var col_val = item[col_name]
        if (col_val in groups) {
          groups[col_val].push(item)
        }
        else {
          groups[col_val] = [item]
        }
      }
      return groups
    },

    group_by_category(raw_data) {
      var categories = this.group_by_col(raw_data, "category")
      return categories
    },

    sort_categories(categories) {
      var cate_names = []
      for (var cate_name in categories) {
        var num    = categories[cate_name][0]["pos"].split("-")[1]
        cate_names.push( [cate_name, parseInt(num)] )
      }
      cate_names.sort(function(a, b) {
        return a[1] - b[1]
      })
      var results = []
      for (var pair of cate_names) {
        results.push(pair[0])
      }
      return results
    },

    get_monthly_total_amount_category(category) {
      // Get total amounts
      var sub_cates = {}
      for (var item of category) {
        var colour = item["colour"];
        var pos = parseInt(item["pos"].split("-")[1])
        if (colour in sub_cates) {
          sub_cates[colour][0] += item["amount"]
        }
        else {
          sub_cates[colour] = [item["amount"], pos]
        }
      }

      // Sort by position
      var results = []
      for (var c in sub_cates) {
        results.push( [c, sub_cates[c][0], sub_cates[c][1]] )
      }
      results.sort(function(a, b) {
        return a[2] - b[2]
      })
      return results
    },

    get_monthly_total_amount(categories) {
      var result = {}
      for (var cate_name in categories) {
        var items = categories[cate_name]
        result[cate_name] = this.get_monthly_total_amount_category(items)
      }
      return result
    },

    format_date(today) {
      return moment(today).format("YYYY-MM-DD")
    },

    filter_by_keyword(keyword) {
      var raw_data = []
      if (keyword == "") {
        raw_data = this.raw_data
      }
      else {
        for (var item of this.raw_data) {
          if (item["category"].indexOf(keyword) >= 0 || 
              item["colour"].indexOf(keyword) >= 0) {
            raw_data.push(item)
          }
        }
      }
      return raw_data
    },

    filter_results(raw_data) {
      var categories = this.group_by_category(raw_data)
      this.sorted_categories = this.sort_categories(categories)
      this.records = this.get_monthly_total_amount(categories)
    },

    get_lastest_date(raw_data) {
      if (raw_data.length == 0) {
        return this.format_date(new Date())
      }
      else {
        var result = raw_data[0]["date"]
        for (var item of raw_data) {
          if (item["date"] > result) {
            result = item["date"]
          }
        }
        return result
      }
    },

    load_last_month_data() {
      var last_month = utils.get_last_month()
      var message = localStorage.getItem("sessionId") + last_month
      var payload = {
        month: btoa(message)
      }
      this.axios.post("https://0wm5vbpwfa.execute-api.ap-southeast-2.amazonaws.com/fetch", payload)
        .then(response => {
          this.raw_data = response.data
          this.filter_results(this.raw_data)
          this.isLoading = false
          this.today = this.get_lastest_date(this.raw_data)
        })
    },

    // Search event (click on search button)
    search_action() {
      var raw_data = this.filter_by_keyword(this.search_keyword)
      this.filter_results(raw_data)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.section {
  margin-top: -30px;
}

.search-bar {
  margin-top: 8px;
}

h1 {
  text-align: left;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0 0 5px 10px;
}

.panel-heading {
  background-color: #d5f2e5;
}

div.box {
  width: 102%;
}

div.notification {
  margin: 4px;
}

table thead th {
  font-size: 0.8em;
  background-color: #f0f0f0;
  border-radius: 4px;
}

table tbody td {
  font-size: 0.9em;
}

span.tag {
  width: 90px;
  text-shadow: 1px 1px 1px #ffffff;
}

span.灰色 {
  background-color: #d9d9d9;
}

span.透明 {
  background-color: #fefefe;
}

span.粉红 {
  background-color: #fce4d6;
}

span.黑色 {
  background-color: #bbbbbb;
}

span.墨绿 {
  background-color: #a9d08e;
}

span.琥珀色 {
  background-color: #fff2cc;
}

span.透明绿 {
  background-color: #e2efda;
}

span.透明红 {
  background-color: #ff6464;
}

a.hidden-button {
  border: none;
  color: white;
  background-color: #fcfcfc;
  width: 40%;
  margin-left: 58%;
  margin-bottom: -50px;
}
</style>
