<template>
  <div class="section">
    <b-navbar>
      <!-- search bar -->
      <template #brand>
        <div class="field has-addons search-bar">
          <div class="control has-icons-left">
            <input class="input is-small" type="text" placeholder="输入关键字" v-model="search_keyword">
            <span class="icon is-left is-small">
              <i class="fa fa-search"></i>
            </span>
          </div>
          <div class="control">
            <a class="button is-success is-small" @click="search_action()">搜索</a>
          </div>
        </div>
      </template>

      <template #start>
          <b-navbar-item href="#/">
            本月库存
          </b-navbar-item>
          <b-navbar-item href="#/detail">
            详细信息
          </b-navbar-item>
          <b-navbar-item href="#/graph">
            月度总结
          </b-navbar-item>
      </template>
    </b-navbar>

    <b-collapse
        aria-id="contentIdForA11y2"
        class="panel"
        animation="slide"
        
        v-for="date in dates" :key="date">
        <template #trigger>
            <div
                class="panel-heading"
                role="button"
                aria-controls="contentIdForA11y2">
                <strong>{{ date }}</strong>
            </div>
        </template>
        <div class="panel-block">
          <div class="media-content">
            <div v-for="(subgroup, cate_name) in records[date]" :key="cate_name" class="content">
              <p><b>{{ cate_name }}</b></p>

              <table class="table is-fullwidth is-hoverable">
              <thead style="display: none">
                <tr>
                  <th>.</th>
                  <th>.</th>
                  <th>.</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in subgroup" :key="item.idx">
                  <td>{{item.fromto}}</td>
                  <td><span class="tag" v-bind:class="item.colour">{{item.colour}}</span></td>
                  <td>{{item.amount}}</td>
                </tr>
              </tbody>
            </table>

            </div>
          </div>
        </div>
    </b-collapse>
    <b-loading is-full-page v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import utils from "../utils"

export default {
  name: 'Detail',
  data() {
    return {
      records: [],
      raw_data: [],
      dates: [],
      search_keyword: "",
      isLoading: false
    }
  },
  created() {
    this.isLoading = true;
    // Load data
    var this_month = utils.get_this_month()
    var message = localStorage.getItem("sessionId") + this_month
    var payload = {
      month: btoa(message)
    }
    this.axios.post("https://0wm5vbpwfa.execute-api.ap-southeast-2.amazonaws.com/fetch", payload)
      .then(response => {
        if (response.data.length > 0) {
          this.raw_data = response.data
          this.records = this.group_by_date_category(this.raw_data)
          this.dates = this.extract_dates(this.records)
          this.isLoading = false
        }
        else {
          this.load_last_month_data()
        }
      })
  },
  mounted() {
    if (!localStorage.getItem("sessionId")) {
      this.$router.push('/login')
    }
    else if (localStorage.getItem("sessionId") != btoa("6871643")) {
      this.$router.push('/login')
    }
  },
  methods: {
    group_by_col(raw_data, col_name) {
      var groups = {}
      for (var item of raw_data) {
        var col_val = item[col_name]
        if (item["fromto"] == "上月底库存") {
          continue
        }
        if (col_val in groups) {
          groups[col_val].push(item)
        }
        else {
          groups[col_val] = [item]
        }
      }
      return groups
    },

    group_by_date_category(raw_data) {
      var tmp = this.group_by_col(raw_data, "date")
      var dates = {}
      for (var date in tmp) {
        var group = tmp[date]
        dates[date] = this.group_by_col(group, "category")
      }
      return dates
    },

    group_by_category_date(raw_data) {
      var tmp = this.group_by_col(raw_data, "category")
      var categories = {}
      for (var cate in tmp) {
        var group = tmp[cate]
        categories[cate] = this.group_by_col(group, "date")
      }
      return categories
    },

    extract_dates(records) {
      var dates = []
      for (var date in records) {
        dates.push(date)
      }
      dates.sort(function(a, b) {
        if (a < b) { return 1 }
        else if (a > b) { return -1 }
        else {return 0}
      })
      return dates
    },

    get_monthly_total_amount(category, colour) {
      var amount = 0;
      for (var item of this.raw_data) {
        if (item["category"] == category && item["colour"] == colour) {
          amount += item["amount"];
        }
      }
      return amount;
    },
    
    filter_by_keyword(keyword) {
      var raw_data = []
      if (keyword == "") {
        raw_data = this.raw_data
      }
      else {
        for (var item of this.raw_data) {
          if (item["category"].indexOf(keyword) >= 0 || 
              item["colour"].indexOf(keyword) >= 0 || 
              item["fromto"].indexOf(keyword) >= 0) {
            raw_data.push(item)
          }
        }
      }
      return raw_data
    },

    load_last_month_data() {
      var last_month = utils.get_last_month()
      var message = localStorage.getItem("sessionId") + last_month
      var payload = {
        month: btoa(message)
      }
      this.axios.post("https://0wm5vbpwfa.execute-api.ap-southeast-2.amazonaws.com/fetch", payload)
        .then(response => {
          this.raw_data = response.data
          this.records = this.group_by_date_category(this.raw_data)
          this.dates = this.extract_dates(this.records)
          this.isLoading = false
        })
    },

    search_action() {
      var raw_data = this.filter_by_keyword(this.search_keyword)
      this.records = this.group_by_date_category(raw_data)
      this.dates = this.extract_dates(this.records)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.section {
  margin-top: -30px;
}

.search-bar {
  margin-top: 8px;
}

p {
  text-align: left;
  padding-left: 10px;
}

.panel-heading {
  background-color: #d5f2e5;
}

p.date {
  text-align: left;
  font-size: 1.8em;
}

div.box {
  width: 102%;
}

div.notification {
  margin: 4px;
}

table tbody tr {
  background-color: #f9f9f9;
}

span.tag {
  width: 80px;
  text-shadow: 1px 1px 1px #ffffff;
}

span.灰色 {
  background-color: #d9d9d9;
}

span.透明 {
  background-color: #fefefe;
}

span.粉红 {
  background-color: #fce4d6;
}

span.黑色 {
  background-color: #bbbbbb;
}

span.墨绿 {
  background-color: #a9d08e;
}

span.琥珀色 {
  background-color: #fff2cc;
}

span.透明绿 {
  background-color: #e2efda;
}

span.透明红 {
  background-color: #ff6464;
}
</style>
