<template>
  <div class="section">
    <div>
      <div class="field has-addons search-bar">
        <div class="control">
          <a class="button is-success is-small" href="#/etf">转至基金页面</a>
        </div>
        <div class="control">
          <a class="button is-success is-small" href="#/stock">转至股票页面</a>
        </div>
      </div>
    </div>
    <br/>
    <table class="table is-fullwidth is-hoverable is-striped">
      <thead>
        <tr>
          <th>编号</th>
          <th>基金</th>
          <th>概率</th>
          <th>正确率</th>
          <th>底线</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in raw_data" :key="item.code">
          <td>{{ item.code }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.prob }}</td>
          <td>{{ item.clfs }}</td>
          <td>{{ item.thr }}</td>
        </tr>
      </tbody>
    </table>
    <b-loading is-full-page v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>

export default {
  name: 'ETF',
  data() {
    return {
      isLoading: true,
      raw_data: []
    }
  },
  created() {
    this.isLoading = true;
    this.axios.post("https://lydeqpu23h.execute-api.ap-southeast-2.amazonaws.com/etf-predict-fetch")
      .then(response => {
        this.raw_data = response.data
        this.raw_data.sort(function(a, b) {
          return b.prob - a.prob
        })
        for (var d of this.raw_data) {
          d.prob = d.prob.toFixed(4)
          d.clfs = d.clfs.toFixed(2)
          d.thr  = d.thr.toFixed(2)
        }
        this.isLoading = false
      })
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.section {
  padding: 8px;
}

div.box {
  width: 102%;
}

div.notification {
  margin: 4px;
}

table thead tr {
  background-color: #e0e0e0;
  font-size: 0.62em;
}

table tbody tr {
  background-color: #f9f9f9;
  font-size: 0.82em;
}

a.button {
  margin-right: 5px;
}
</style>
