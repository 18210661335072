<template>
  <div class="section">
    <b-navbar>
      <!-- search bar -->
      <template #brand>
        <div class="field has-addons search-bar">
          <div class="control">
            <a class="button is-success is-small" @click="generate_graphs_this_month()">本月图表</a>
          </div>
          <div class="control">
            <a class="button is-success is-small" @click="generate_graphs_last_month()">上月图表</a>
          </div>
        </div>
      </template>

      <template #start>
          <b-navbar-item href="#/">
            本月库存
          </b-navbar-item>
          <b-navbar-item href="#/detail">
            详细信息
          </b-navbar-item>
          <b-navbar-item href="#/graph">
            月度总结
          </b-navbar-item>
      </template>
    </b-navbar>

    <!-- 每日出库 -->
    <!-- <b-collapse
        aria-id="contentIdForA11y2"
        class="panel"
        animation="slide">
      <template #trigger>
        <div
            class="panel-heading"
            role="button"
            aria-controls="contentIdForA11y2">
            <strong>每日出库量</strong>
        </div>
      </template>
      <div class="panel-block">
        <div class="media-content">

        </div>
      </div>
    </b-collapse> -->

    <!-- 客户 -->
    <b-collapse
        aria-id="contentIdForA11y2"
        class="panel"
        animation="slide">
      <template #trigger>
        <div
            class="panel-heading"
            role="button"
            aria-controls="contentIdForA11y2">
            <strong>客户出库数量</strong>
        </div>
      </template>
      <div class="panel-block">
        <div class="media-content">
          <canvas id="customer-chart"></canvas>
        </div>
      </div>
    </b-collapse>

    <!-- 种类出库 -->
    <b-collapse
        aria-id="contentIdForA11y2"
        class="panel"
        animation="slide">
      <template #trigger>
        <div
            class="panel-heading"
            role="button"
            aria-controls="contentIdForA11y2">
            <strong>出库种类统计</strong>
        </div>
      </template>
      <div class="panel-block">
        <div class="media-content">
          <canvas id="export-chart"></canvas>
        </div>
      </div>
    </b-collapse>

    <!-- 库存 -->
    <b-collapse
        aria-id="contentIdForA11y2"
        class="panel"
        animation="slide">
      <template #trigger>
        <div
            class="panel-heading"
            role="button"
            aria-controls="contentIdForA11y2">
            <strong>月初月底库存</strong>
        </div>
      </template>
      <div class="panel-block">
        <div class="media-content">
          <canvas id="storage-chart"></canvas>
        </div>
      </div>
    </b-collapse>

    <b-loading is-full-page v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>

import Chart from 'chart.js'
import utils from "../utils"

export default {

  data() {
    return {
      raw_data: [],
      isLoading: false
    }
  },

  mounted() {
    // Load data
    var this_month = utils.get_this_month()
    this.generate_graphs(this_month)
  },

  methods: {
    generate_graphs(target_month) {
      this.isLoading = true
      var message = localStorage.getItem("sessionId") + target_month
      var payload = {
        month: btoa(message)
      }
      this.axios.post("https://0wm5vbpwfa.execute-api.ap-southeast-2.amazonaws.com/fetch", payload)
        .then(response => {
          this.raw_data = response.data
          // 1. 客户统计
          var customer_data = this.get_customers(this.raw_data)
          var customer_source_data = customer_data[1]
          var customer_labels = customer_data[0]
          this.draw_doughnut("customer-chart", customer_source_data, customer_labels)

          // 2. 出库种类
          var export_data = this.get_exports(this.raw_data)
          var export_source_data = export_data[1]
          var export_labels = export_data[0]
          this.draw_doughnut("export-chart", export_source_data, export_labels)

          // 3. 月初月底库存
          var storage_data = this.get_storage(this.raw_data)
          var storage_labels = storage_data[0]
          var this_month_storage = storage_data[1]
          var last_month_storage = storage_data[2]
          this.draw_bar(storage_labels, this_month_storage, last_month_storage)

          this.isLoading = false
        })
    },

    generate_graphs_this_month() {
      var this_month = utils.get_this_month()
      console.log(this_month)
      this.generate_graphs(this_month)
    },

    generate_graphs_last_month() {
      var last_month = utils.get_last_month()
      console.log(last_month)
      this.generate_graphs(last_month)
    },

    generate_color() {
      const r = Math.round (Math.random () * 255);
      const g = Math.round (Math.random () * 155 + 50);
      const b = Math.round (Math.random () * 60 + 150);
      const background = `rgba(${r}, ${g}, ${b}, 0.5)`
      const border = `rgba(${r}, ${g}, ${b}, 1)`
      return [background, border]
    },

    draw_doughnut(canvas, source_data, labels) {
      var ctx  = document.getElementById(canvas);
      var backgroundColor = [];
      var borderColor     = [];
      for (var i = 0; i < source_data.length; i++) {
        var colour = this.generate_color()
        backgroundColor.push(colour[0])
        borderColor.push(colour[1])
      }
      var graph_data = {
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: [{
            // label: '# of Tomatoes',
            data: source_data,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 2
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        }
      }
      new Chart(ctx, graph_data)
    },

    get_statistics(raw_data, col_val) {
      var groups = []
      for (var item of raw_data) {
        var col_name = item[col_val]
        var amount = item["amount"]
        if (amount < 0) {
          if (col_name in groups) {
            groups[col_name] -= amount
          }
          else {
            groups[col_name] = -amount
          }
        }
      }
      return groups
    },

    get_monthly_initial_amount(raw_data) {
      var groups = []
      for (var item of raw_data) {
        var cate_name = item["category"]
        var amount = item["amount"]
        var type   = item["fromto"]
        if (type == "上月底库存") {
          if (cate_name in groups) {
            groups[cate_name] = amount
          }
          else {
            groups[cate_name] += amount
          }
        }
      }
      return groups
    },

    get_monthly_final_amount(raw_data) {
      var groups = []
      for (var item of raw_data) {
        var cate_name = item["category"]
        var amount = item["amount"]
        if (cate_name in groups) {
          groups[cate_name] += amount
        }
        else {
          groups[cate_name] = amount
        }
      }
      return groups
    },

    // 出库种类统计
    get_exports(raw_data) {
      // 1. 统计
      var groups = this.get_statistics(raw_data, "category")
      // 2. 排序
      return this.sort_data(groups)
    },

    // 出库客户统计
    get_customers(raw_data) {
      // 1. 统计
      var groups = this.get_statistics(raw_data, "fromto")
      // 2. 排序
      return this.sort_data(groups)
    },

    // 月初、月末库存统计
    get_storage(raw_data) {
      // 1. 统计月初、月底数量
      var last_month_total = this.get_monthly_initial_amount(raw_data)
      var this_month_total = this.get_monthly_final_amount(raw_data)
      var results = []
      for (var cate in this_month_total) {
        var this_mon = this_month_total[cate]
        var last_mon = 0
        if (cate in last_month_total) {
          last_mon = last_month_total[cate]
        }
        results.push( [cate, this_mon, last_mon] )
      }
      // 2. 排序
      results.sort(function(a, b) {
        return (b[1]+b[2]) - (a[1]+a[2])
      })
      // 3. 标签、数据拆分
      var labels = []
      var last_month_data = []
      var this_month_data = []
      for (var item of results) {
        labels.push(item[0])
        this_month_data.push(item[1])
        last_month_data.push(item[2])
      }
      return [ labels, this_month_data, last_month_data ]
    },

    draw_bar(labels, this_month_data, last_month_data) {
      var ctx  = document.getElementById("storage-chart");
      var graph_data = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: "月初库存",
              data: last_month_data,
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgb(54, 162, 235)',
              borderWidth: 1
            },
            {
              label: "月底库存",
              data: this_month_data,
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgb(255, 99, 132)',
              borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          y: {
            beginAtZero: true
          },
        }
      }
      new Chart(ctx, graph_data)
    },

    sort_data(stat_dict) {
      var pairs = []
      for (var cate_name in stat_dict) {
        pairs.push( [ cate_name, stat_dict[cate_name] ] )
      }
      pairs.sort(function(a, b) {
        return b[1] - a[1]
      })
      var labels = []
      var data = []
      for (var pair of pairs) {
        labels.push(pair[0])
        data.push(pair[1])
      }
      return [ labels, data ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section {
  margin-top: -30px;
}

.panel-heading {
  background-color: #d5f2e5;
}

.search-bar {
  margin-top: 10px;
}

.search-bar .button {
  margin-right: 5px;
}

canvas {
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 15px;
  height: 260px !important;
  width: 90% !important;
}
</style>
