import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Graph from '../components/Graph.vue'
import Total from '../components/Total.vue'
import Detail from '../components/Detail.vue'
import Etf from '../components/Etf.vue'
import Stock from '../components/Stock.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Total',
    component: Total
  },
  {
    path: '/detail',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/graph',
    name: 'Graph',
    component: Graph
  },
  {
    path: '/etf',
    name: 'Etf',
    component: Etf
  },
  {
    path: '/stock',
    name: 'Stock',
    component: Stock
  }
]

const router = new VueRouter({
  routes
})

export default router
