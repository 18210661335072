<template>
  <div class="section">
    <b-message title="登录系统"  :closable="false">

      <b-field label="请输入密码">
        <b-input type="password" v-model="password"
        value=""
        password-reveal></b-input>
      </b-field>
      <b-message type="is-danger" :style="{display: showError}">
        &#10060; 密码错误，请重新输入！
      </b-message>

      <br/>
      <b-button type="is-success" expanded @click="login()">登录</b-button>
    </b-message>


  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      msg: "Home Page",
      password: "",
      showError: "None"
    }
  },

  methods: {
    login() {
      if (this.password == "6871643") {
        this.showError = "None"
        localStorage.setItem("sessionId", btoa(this.password))
        this.$router.push('/')
      }
      else {
        this.showError = "";
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
