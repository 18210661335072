
class Utils {
    constructor() {

    }

    convert_year_month(year, month) {
        if (month == 0) {
            year  = year - 1
            month = 12
        }
        if (month >= 1 && month <= 9) {
            month = "0" + month.toString()
        }
        return year.toString() + "-" + month.toString() + "-"
    }

    get_this_month() {
        var today = new Date()
        var month = today.getMonth() + 1
        var year  = today.getFullYear()
        return this.convert_year_month(year, month)
    }

    get_last_month() {
        var today = new Date()
        var month = today.getMonth()
        var year  = today.getFullYear()
        return this.convert_year_month(year, month)
    }
}

var utils = new Utils()

export default utils;